import React from "react";
import Map from "../common/Map";
import TeamMemberCard from "./TeamMemberCard";
import { TeamMembers } from "./TeamMembers";
import a2 from "../../assets/images/a2.png";

export default function About() {
  return (
    <div className="flex flex-col justify-center items-center z-neg-10">
      <div className="relative xl:w-3/4 flex flex-col p-6 text-slate-700 relative items-center lg:items-start mx-auto">
        <div className="absolute right-0 top-16 w-40 lg:block hidden">
          <img src={a2} alt="user" />
        </div>
        <div className="contact-header lg:mt-24 mt-4 lg:text-left text-center w-full lg:w-1/2">
          <h1 className="lg:text-5xl text-4xl mb-4">About us</h1>
          <p className="text-lg md:text-1xl mt-4">Martin Delany, CEO of BuzzHubs, tells the origin story ...</p>
        </div>
        <div className="flex flex-col text-slate-600 py-6 text-justify">
          <p className="xl:text-3xl lg:text-3xl md:text-3xl text-2xl mb-6 text-slate-700 text-left">
            The Birth of BuzzHubs
          </p>
          <p className="text-lg md:text-1xl mt-4">
            It’s April 2020 and a global pandemic is causing devastation all
            over the world as hospitals struggle to cope with a new virus called
            Covid-19. With no vaccine and countries scrambling for treatment
            options, government work from home directives force businesses to
            abandon the old school office and set up home working en masse.
          </p>
          <p className="text-lg md:text-1xl mt-4">
            So while some industries got hammered with the wrong end of the
            stick, many office based companies carried on more or less as
            normal. It was no real surprise that many people loved this new
            found flexibility and benefited from less stress, more money in
            their pockets and time saved commuting. I’ll hold my hand up first
            and say “I’m one”. I personally benefited from spending more time at
            home and having a more flexible working day. Did I work less and
            achieve less, no, but I had more time in the day and dived head
            first into flexible working. At this point, my youngest son was 4
            and a real “Mummy’s boy”. No such thing as a goodnight kiss for
            Daddy as he just wanted his Mummy. Fast forward a few months and he
            wouldn’t accept anyone other than Daddy putting him to bed. His
            “your face is too spiky” excuse which sounded straight out of a
            “That’s not my Daddy” book was consigned to history and we became
            best buddies. I did and still do things I had incredibly never done
            before, simple stuff like pick the kids up from school and take them
            to the park, play football with them in the garden at 3.30pm on a
            Monday or go for an ice cream on a Friday afternoon. Flexible
            working opened the door to a lifestyle which had previously been off
            limits.
          </p>
          <p className="text-lg md:text-1xl mt-4">
            Gary, our Developer Guru, emigrated to the Rockies so he could live
            his passion and snowboard several months of the year. He fully
            embraced a work anywhere approach and delivers on his goals flexibly
            around his family and hobbies. If you love snowboarding and you can
            live in the Rockies then why the **** not?
          </p>
        </div>
        <div className="mx-auto flex flex-col text-slate-600 py-6 text-justify">
          <p className="xl:text-3xl lg:text-3xl md:text-3xl text-2xl mb-6 text-slate-700 text-left">
            Square Pegs in Round Holes?
          </p>
          <p className="text-lg md:text-1xl mt-4">
            But while this was great personally, there were some cracks
            appearing in the business culture, motivation and engagement. Sure
            we embraced all the tools of the day from video calling to project
            management to instant messaging, and they do 100% help. But they
            were not built for remote first business, and it did feel like
            papering over the cracks as we were running a remote first business
            as if it was an office based one. Like square pegs in round holes,
            businesses and its technology were not designed primarily for remote
            working and trying to recreate an office set up digitally is doomed
            for failure.
          </p>
          <p className="text-lg md:text-1xl mt-4">
            So faced with an obstacle, some usually astute business leaders
            immediately yearned to get their staff back in the office and “shake
            some hands”, as if this will magically solve all their problems. It
            wouldn’t, and more importantly, it would miss a once in a lifetime
            opportunity to create something new, and something a whole lot
            better.
          </p>
        </div>
        <div className="mx-auto flex flex-col text-slate-600 py-6 text-justify">
          <p className="xl:text-3xl lg:text-3xl md:text-3xl text-2xl mb-6 text-slate-700 text-left">
            Performance over Presence, Real Time Recognition and Trust through
            Transparency
          </p>
          <p className="text-lg md:text-1xl mt-4">
            So with all this in mind, BuzzHubs was born. Firstly with an
            unshakeable belief that it would help our existing business, but
            always with equal belief that it can help many other businesses too.
            We wanted to give thousands more businesses the confidence to offer
            home working so all their employees could benefit from a better
            work/life balance, all the while increasing business performance at
            the same time.
          </p>
          <p className="text-lg md:text-1xl mt-4">
            This is what BuzzHubs delivers, it gives the best of both worlds
            where the business itself and their people win. We didn’t achieve
            this by trying to recreate the out-dated office. We achieved this by
            rewriting what work looks like, by providing the platform to tear up
            workplace geography and give diversity and inclusion an environment
            to thrive.
          </p>
          <p className="text-lg md:text-1xl mt-4">
            Work and culture built on performance over presence, real time
            recognition and trust through transparency. Work with us and watch
            how BuzzHubs can help send your people’s desire to succeed through
            the roof and deliver a level of performance you never thought
            possible.
          </p>
          <p className="text-lg md:text-1xl mt-4">
            Make it happen and make a better working world.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col py-6">
            <p className="dark-text text-4xl md:text-5xl mb-4">
              <b>Our Team</b>
            </p>

            <div className="flex flex-row flex-row flex-wrap justify-center content-center">
              {TeamMembers.map((member) => {
                return (
                  <TeamMemberCard key={`card-${member.Name}`} member={member} />
                );
              })}
            </div>
            <div className="w-full h-96 mx-aut mt-16 mb-32 mx-auto">
              <p className="dark-text font-bold lg:text-5xl text-4xl mb-4">
                Where are we located?
              </p>
              <p className="mb-4">
                Use the map below to find our pins! We are located in Canada, UK
                and New Zealand
              </p>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
