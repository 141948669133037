import emailjs from "@emailjs/browser";
emailjs.init("MzrJMbft_wHl6W8Xv");

export function sendEmail(templateParams, template) {
  return emailjs.sendForm(
    "service_jkgcmnn",
    template ? template : "template_ar33ioz",
    templateParams,
    "MzrJMbft_wHl6W8Xv"
  );
}
