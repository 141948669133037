import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = () => (
  <div>
    {<FontAwesomeIcon icon={faLocationDot} className="text-purple-600 h-10" />}
  </div>
);

export default function Map() {
  const defaultProps = {
    center: {
      lat: 59,
      lng: -6,
    },
    zoom: 1,
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyCdtdsGL3hGlGgL9kAYq_FjAIjwyEg8haw",
      }}
      
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent lat={59} lng={-6} />
      <AnyReactComponent lat={64} lng={-114} />
      <AnyReactComponent lat={-37} lng={-190} />
    </GoogleMapReact>
  );
}
