import {
  faEyeSlash,
  faFaceLaughBeam,
  faFaceMeh,
  faFaceTired,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightLong,
  faBrain,
  faBuildingCircleXmark,
  faGlobe,
  faHand,
  faHouseSignal,
  faLink,
  faLinkSlash,
  faLocationPinLock,
  faNetworkWired,
  faRightLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import a2 from "../../assets/images/a2.png";

export default function Introducers() {
  return (
    <>
      <div className="flex flex-col justify-center items-center z-neg-10">
        <div className="w-full">
          <div className="xl:w-3/4 flex flex-col p-6 text-slate-700 relative items-center lg:items-start mx-auto my-16">
            <div
              className="lg:block hidden absolute top-4 right-20 h-40 w-40"
              style={{ top: "20px !important" }}
            >
              <img src={a2} alt="user" />
            </div>
            <div className="lg:text-left text-center w-full lg:w-1/2">
              <h1 className="lg:text-5xl text-4xl mb-4 font-bold">
                Introducers
              </h1>
              <p className="text-lg md:text-1xl my-4">
                If you interested in becoming an Introducer please contact us.</p>
            </div>
            <button
              onClick={() => (window.location.href = "/contact-us")}
              className="lg:my-1 my-6 rounded-md bg-orange px-6 py-2.5 text-white text-md lg:text-lg w-60 xl:mx-0 mx-auto"
            >
              Contact Us
            </button>
            <div className="divider" />
          </div>
        </div>
        <div className="w-full bg-slate-200">
          <div className="xl:w-3/4 flex flex-col lg:flex-row p-6 text-slate-700 mx-auto items-center">
            <div className="my-6 lg:text-left text-center w-full">
              <h1 className="lg:text-5xl text-4xl mb-4 font-bold">
                What you need to know
              </h1>

              <p className="text-justify">
                BuzzHubs is innovative and state of the art software built
                specifically and exclusively for modern businesses adapting to
                the new world of work. It focuses on performance over presence,
                workforce engagement, fair and consistent recognition, trust and
                transparency and communication.
              </p>
            </div>
            <div className="divider" />
          </div>
        </div>
        <div className="w-full">
          <div className="xl:w-3/4 flex flex-col lg:flex-row p-6 text-slate-700 mx-auto items-center">
            <div className="mt-4 lg:text-left text-center w-full">
              <h1 className="lg:text-5xl text-4xl mb-4 font-bold">
                How exactly does BuzzHubs help?
              </h1>

              <p className="my-4 text-justify">
                Without personal connections work is just a job and a list of
                tasks so there’s no loyalty to the company. People can still
                feel productive working by themselves but when work feels
                transactional, it’s very easy to trade one laptop for another.
              </p>
              <p className="my-4 text-justify">
                BuzzHubs helps businesses build one company culture by
                developing a sense of belonging. It helps build connections
                between workers through instant messaging and a social media
                style news feed. Automated achievement posts are combined with
                questions and company announcements, while colleagues
                congratulate each other on workplace wins.
              </p>
              <p className="my-4 text-justify">
                BuzzHubs encourages employees to share updates throughout the
                day, so coworkers can see when they’re available, when they’re
                out and about, or when they're focusing on achieving a goal.
              </p>
              <p className="my-4 text-justify">
                Employees can volunteer their status and optional integrations
                with calendars and VOIP systems allow colleagues to
                automatically see what someone is working on in real time. The
                expected back indicator stops workers being contacted when their
                busy, on holiday or on their downtime. BuzzHubs shows when
                people are truly available, not just when they’re online.
              </p>
            </div>
            <div className="divider" />
          </div>
        </div>
        <div className="w-full bg-slate-200">
          <div className="xl:w-3/4 flex flex-col lg:flex-row p-6 text-slate-700  mx-auto">
            <div className="text-lg lg:mt-24 mt-4 text-center w-full">
              <h1 className="lg:text-5xl text-4xl mb-4 font-bold">
                Key Problems Businesses are Facing
              </h1>

              <div className="flex flex-wrap justify-evenly gap-10 mt-10">
                <div className="flex md:flex-row flex-col  gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faLocationPinLock} size="3x" />
                    <p className="my-5">
                      Companies realise to achieve their growth goals and build
                      a diverse company they need to access a bigger talent pool
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <FontAwesomeIcon icon={faGlobe} size="3x" />

                    <p className="my-5">
                      BuzzHubs helps your business operate from anywhere,
                      allowing you to create a truly diverse and talented team
                    </p>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col  gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faHand} size="3x" />
                    <p className="my-5">
                      Employees needing help can struggle to get timely support
                      when working remotely leading to poor customer outcomes
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <p className="text-5xl">SOS</p>
                    <p className="my-5">
                      Get the right help faster with remote SOS, a live chat
                      system between workers and supervisors
                    </p>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col  gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faEyeSlash} size="3x" />
                    <p className="mt-5">
                      Managers can miss changes in performance and the right
                      people won't always get recognised or supported
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <FontAwesomeIcon icon={faBrain} size="3x" />

                    <p className="my-5">
                      Artificial Intelligence works diligently in the background
                      to supercharge your leaders with the information they need
                      to recognise and support where required
                    </p>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col  gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faFaceMeh} size="3x" />
                    <p className="mt-5">
                      Too many systems can leave workers frustrated and missing
                      key information or opportunities to collaborate
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <FontAwesomeIcon icon={faLink} size="3x" />

                    <p className="my-5">
                      With BuzzHubs your systems are connected. No more jumping
                      from one system to another to get stats and sales figures!
                    </p>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col  gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faFaceTired} size="3x" />
                    <p className="mt-5">
                      Workers suffer burnout due to an always on culture driven
                      by a lack of communication and understanding of
                      availability
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <FontAwesomeIcon icon={faFaceLaughBeam} size="3x" />

                    <p className="my-5">
                      Get visibility on performance and availability through a
                      real time visualisation of the entire workforce along with
                      our Feed; It is the perfect place to communicate and share
                      news with social media style posts that users can like and
                      comment on
                    </p>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faBuildingCircleXmark} size="3x" />
                    <p className="mt-5">
                      Businesses want to grow their teams but don't want the
                      expense and logistics of acquiring new office space
                    </p>
                  </div>

                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <FontAwesomeIcon icon={faHouseSignal} size="3x" />

                    <p className="my-5">
                      BuzzHubs is built around remote workers and gives managers
                      all of the tools needed to effectively manage employees
                      regardless of their physical location
                    </p>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col  gap-4 items-center">
                  <div className="lg:w-96 w-auto  bg-white rounded h-auto p-8 shadow-lg">
                    <FontAwesomeIcon icon={faLinkSlash} size="3x" />
                    <p className="mt-5">
                      Having staff in different office locations or working from
                      home can lead to two cultures and disconnected and
                      dissatisfied workers
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={faRightLeft}
                    size="2x"
                    className="rotate-90 md:rotate-0"
                  />
                  <div className="lg:w-96 w-auto  bg-white shadow-lg rounded h-auto p-8 relative overflow-hidden">
                    <div className="absolute bg-green-600 text-white py-1 px-4 rounded top-0 left-0">
                      <p>Solution</p>
                    </div>
                    <FontAwesomeIcon icon={faNetworkWired} size="3x" />

                    <p className="my-5">
                      Connected colleagues sharing each others successes to
                      build one culture across multiple locations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-slate-200">
          <div className="xl:w-3/4 flex flex-col p-6 text-slate-700 mx-auto">
            <div className="text-lg lg:mt-24 mt-4 text-center w-full">
              <h1 className="lg:text-5xl text-4xl mb-4 font-bold">
                How does this work?
              </h1>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row lg:w-2/3 w-full mx-auto my-6 items-center md:text-left">
                <p className="text-3xl w-24">STEP 1</p>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className="md:mx-6 md:my-0 my-6 rotate-90 md:rotate-0"
                />
                <p className="text-2xl">
                  You apply for approved introducer status
                </p>
              </div>
              <div className="flex flex-col md:flex-row  lg:w-2/3 w-full mx-auto my-6 items-center md:text-left">
                <p className="text-3xl w-24">STEP 2</p>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className="md:mx-6 md:my-0 my-6 rotate-90 md:rotate-0"
                />
                <p className="text-2xl">
                  You refer potential clients to your BuzzHubs Business
                  Development Manager
                </p>
              </div>
              <div className="flex flex-col md:flex-row  lg:w-2/3 w-full mx-auto my-6 items-center md:text-left">
                <p className="text-3xl w-24">STEP 3</p>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className="md:mx-6 md:my-0 my-6 rotate-90 md:rotate-0"
                />
                <p className="text-2xl">
                  We contact the client to arrange a free demo
                </p>
              </div>
              <div className="flex flex-col md:flex-row  lg:w-2/3 w-full mx-auto my-6 items-center md:text-left">
                <p className="text-3xl w-24">STEP 4</p>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className="md:mx-6 md:my-0 my-6 rotate-90 md:rotate-0"
                />
                <p className="text-2xl">
                  The client chooses a package and level of automation to suit
                  their needs
                </p>
              </div>
              <div className="flex flex-col md:flex-row  lg:w-2/3 w-full mx-auto my-6 items-center md:text-left">
                <p className="text-3xl w-24">STEP 5</p>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className="md:mx-6 md:my-0 my-6 rotate-90 md:rotate-0"
                />
                <p className="text-2xl">
                  You receive 20% of the income in year 1 and 10% of the income
                  in year 2
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}
