import MartinImage from "../../assets/images/user-badge/martin.jpg";
import ConnorImage from "../../assets/images/user-badge/connor.jpg";
import GaryImage from "../../assets/images/user-badge/gary.jpg";

export const TeamMembers = [
  {
    FirstName: "Martin",
    LastName: "Delany",
    ImageUrl: MartinImage,
    Title: "CEO",
    Avatar: "https://avatars0.githubusercontent.com/u/527058?s=460&v=4",
    Description:
      "Previously co-founded Lifetime Protection Services, Bon Accord Mortgages and SaveOnLife.co.uk.  Organically grew from kitchen table to 100+ office based employees in 6 years.  Covid-19 forced a re-think of what work looks like and successfully started BuzzHubs to transform the way the other businesses operated.  Resulted in higher staff retention, increased performance, higher staff engagement scores and downsized from 7000 to 1500 sq ft of office space.  Passionate about delivering a better working world for as many businesses as possible where flexible working means higher productivity and happier employees.",
    Flag: "GB-SCT",
  },
  {
    FirstName: "Gary",
    LastName: "Joynes",
    ImageUrl: GaryImage,
    Title: "CTO",
    Avatar: "https://avatars0.githubusercontent.com/u/527058?s=460&v=4",
    Description:
      "Founder of multiple SaaS products and SaveOnLife.co.uk. Has grown software solutions from startup phase to mature commercial systems processing large numbers of transactions and concurrent users.  Currently applying a passion for software development to the challenges of remote working.",
    Flag: "CAN",
  },
  {
    FirstName: "Connor",
    LastName: "Finan",
    ImageUrl: ConnorImage,
    Title: "Senior Frontend Engineer",
    Avatar: "https://avatars0.githubusercontent.com/u/527058?s=460&v=4",
    Description:
      "Previously worked with Martin and Gary at SaveOnLife.co.uk developing the front end of one of Scotlands Largest online life insurance brokers. Having built up a lot of experience working over multiple languages and framworks which have helped build and maintain multiple websites for high profile clients such as Celtic Compare and Rangers Protect. ",
    Flag: "GB-SCT",
  },
  {
    FirstName: "Ben",
    LastName: "Jones",
    ImageUrl: "https://avatars0.githubusercontent.com/u/527058?s=460&v=4",
    Title: "Senior Backend Engineer",
    Avatar: "https://avatars0.githubusercontent.com/u/527058?s=460&v=4",
    Description:
      "Founder of multiple SaaS products alongside Gary. Was in charge of scaling these companies growing from 1-2 million records per month to 100m+ per month. Previously worked for a company which created billing solutions for energy and utility companies in which he was a systems architect for a product that processed hundreds of millions of records per month across servers distributed around the globe that maintained a 99.99% uptime.",
    Flag: "NZL",
  },
];
