import { faCheck, faUserAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const cards = [
  {
    name: "Essentials",
    price: "Free",
    description: "Entry tool for visualisation and recognition",
    icon: faUserAlt,
    points: [
      "Real Time Visualisation",
      "Real Time User Stats",
      "Instant Messaging",
      "Breaking News Recognition",
      "Manual Status Updates",
    ],
  },
  {
    name: "Managed",
    price: "19.95",
    description: "An automated solution to drive business performance",
    icon: faUsers,
    points: [
      "Everything in Essentials",
      "Automated Recognition",
      "Automated Status Updates",
      "Click to Meet",
      "Automated Company News",
      "CRM integrations",
    ],
    popular: true,
  },
  {
    name: "Enterprise",
    price: "35.95",
    description: "An advanced and customisable platform",
    icon: faUsers,
    points: [
      "Everything in Managed",
      "Call, message, meet, and support your customers from anywhere",
      "Unlimited calling, SMS, & MMS",
      "Ai-powered call and voicemail transcriptions",
      "Google Workspace & Microsoft 365 integrations",
      "Unlimited video meetings",
      "Fully Customisable",
    ],
  },
];

export default function Pricing() {
  return (
    <div className="bg-slate-100 py-16 flex flex-col">
      <p className="lg:text-5xl text-4xl mb-8 text-slate-700">Pricing</p>
      <div className="flex xl:flex-row flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center xl:items-start items-center gap-14">
        {cards.map((card) => {
          return pricingCard(
            card.name,
            card.price,
            card.description,
            card.icon,
            card.points,
            card.popular
          );
        })}
      </div>
    </div>
  );
}

function pricingCard(name, price, description, icon, points, popular) {
  return (
    <div
      key={`pricingcard-${name}`}
      className="relative bg-white rounded-xl shadow py-8 px-2 flex flex-col text-slate-700 justify-center w-80 overflow-hidden"
    >
      {popular && (
        <div
          className="absolute bg-orange-500 text-white rotate-45 px-10"
          style={{ top: 30, right: -45 }}
        >
          <p>Most Popular</p>
        </div>
      )}
      <FontAwesomeIcon icon={icon} size="4x" />
      <p className="text-3xl my-4">{name}</p>
      <p className="text-2xl">{description}</p>
      <p className="text-slate-500 my-10">
        £<span className="text-5xl text-slate-800">{price}</span> / user *
      </p>
      <div className="text-left px-6 gap-1 flex flex-col">
        {points.map((point) => {
          return (
            <p>
              <FontAwesomeIcon icon={faCheck} /> {point}
            </p>
          );
        })}
      </div>
      <button
        onClick={() => (window.location.href = `/book-a-demo?plan=${name}`)}
        className="bg-slate-700 text-white py-2 px-4 rounded w-2/3 mx-auto mt-6"
      >
        Sign Up
      </button>
    </div>
  );
}
