import React from "react";
import logo from "../../assets/images/logo_transparent.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <header className="w-full sticky top-0 lg:static z-10">
      {/* this can be added back in when we have information to display here */}
      <div className="bg-orange h-12 hidden lg:flex justify-center items-center text-white">
        <p>📰Check out our most recent blog post!🗞️🎉</p>
        <button
          onClick={() => (window.location.href = "https://blog.buzzhubs.com/")}
          className="ml-4 rounded-md px-4 border py-0.5 border border-orange-900"
        >
          Read more
        </button>
      </div>
      <div className="bg-white w-screen xl:w-3/4 w-5/6 mx-auto relative">
        <div className="hidden lg:flex justify-end items-center h-9 text-orange">
          <p>
            <b>TEL:</b> <a href="tel:+441172051115">0117 205 1115</a>
          </p>
        </div>
        <div className="flex flex-row justify-between content-center h-16">
          <div className="flex p-2.5">
            <img
              style={{ cursor: "pointer", width: "auto", height: "100%" }}
              src={logo}
              alt="BuzzHubs"
              onClick={() => (window.location.href = "/")}
            />
          </div>
          <ul
            className={`lg:z-10 z-neg-10 lg:flex-row flex-col items-start lg:items-center lg:top text-slate-800 flex lg:static absolute  ${
              sidebarOpen ? "top-16" : "top-neg-400"
            } w-screen lg:w-auto animate-down bg-white rounded-b-md lg:shadow-none shadow-lg`}
          >
            <li className="lg:m-3 m-4 text-slate-700">
              <a href="/about-us" rel="noreferrer">
                About Us
              </a>
            </li>
            <li className="lg:m-3 m-4 text-slate-700">
              <a href="/introducers" rel="noreferrer">
                Introducers
              </a>
            </li>
            <li className="lg:m-3 m-4 text-slate-700">
              <a
                href="https://blog.buzzhubs.com/"
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
            <li className="lg:m-3 m-4 text-slate-700">
              <a href="/pricing" rel="noreferrer">
                Pricing
              </a>
            </li>
            <a
              href="https://app.buzzhubs.com/login"
              rel="noreferrer"
              className="lg:m-1 m-4 rounded-md lg:bg-yellow-500 lg:px-6 lg:py-1.5 lg:text-white text-slate-700"
            >
              Login
            </a>
            <a
              href="/contact-us"
              rel="noreferrer"
              className="lg:m-1 m-4 lg:rounded-md lg:px-6 lg:border lg:py-1.5 lg:border-yellow-500 lg:text-yellow-500 text-slate-700"
            >
              Contact Us
            </a>
          </ul>
          <div className="lg:hidden flex items-center h-16 mx-8">
            <FontAwesomeIcon
              icon={faBars}
              className="text-slate-800"
              size="lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            />
          </div>
        </div>
      </div>
    </header>
  );
}
