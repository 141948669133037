import {
  faBuilding,
  faEnvelope,
  faPhoneAlt,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import a2 from "../../assets/images/a2.png";
import Map from "../common/Map";
import { sendEmail } from "../common/EmailMiddleware";

export default function BookDemo() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  function sendForm(e) {
    e.preventDefault();
    sendEmail(e.target)
      .then((res) => {
        setSuccess("Thank you! Your request has been sent successfully!");
        setLoading(false);
        clearForm();
      })
      .catch((err) => {
        setError(
          `There seems to have been an issue sending your request. Please try again.`
        );
        setLoading(false);
      });
  }

  function clearError() {
    setError(null);
  }

  function clearForm() {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
    setMessage("");
  }

  return (
    <div className="flex flex-col justify-center items-center z-neg-10">
      <div>
        <div className="xl:w-3/4 flex flex-col p-6 text-slate-700 relative items-center lg:items-start mx-auto">
          <div className="contact-us-image lg:block hidden">
            <img src={a2} alt="user" />
          </div>
          <div className="contact-header lg:mt-24 mt-4 lg:text-left text-center w-full lg:w-1/2">
            <h1 className="lg:text-5xl text-4xl mb-4">Book a Demo</h1>

            <p>
              Are you interested in seeing exactly how BuzzHubs can help you?
              Book a demo now and have a dedicated account manager help run
              through all of our features in realtime!
            </p>
          </div>
          <div className="divider" />
          <form
            className="w-full mx-auto mt-2"
            id="contact-form"
            onSubmit={(e) => {
              sendForm(e);
              setLoading(true);
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                  className="appearance-none block w-full bg-blue-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    clearError();
                  }}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <input
                  className="appearance-none block w-full bg-blue-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    clearError();
                  }}
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <input
                  className="appearance-none block w-full bg-blue-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="emailAddress"
                  type="email"
                  placeholder="E-mail"
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                    clearError();
                  }}
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <input
                  className="appearance-none block w-full bg-blue-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="contact-number"
                  name="contactNumber"
                  type="number"
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={(e) => {
                    setContactNumber(e.target.value);
                    clearError();
                  }}
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <textarea
                  className=" no-resize appearance-none block w-full bg-blue-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                  id="message"
                  name="message"
                  value={message}
                  placeholder="Message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                    clearError();
                  }}
                ></textarea>

                {error && (
                  <div className="bg-red-500 rounded mt-2">
                    <p className="text-white italic md:text-lg text-md px-1">
                      {error}
                    </p>
                  </div>
                )}
                {success && (
                  <div className="bg-green-500 rounded mt-2">
                    <p className="text-white italic md:text-lg text-md px-1">
                      {success}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="mr-2">
                <input
                  className="shadow bg-orange-600 hover:bg-orange-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="submit"
                  value="Send"
                  disabled={loading}
                />
              </div>
              {loading && (
                <FontAwesomeIcon
                  className="animate-spin"
                  icon={faSpinner}
                  style={{ height: 25, width: 25 }}
                />
              )}
            </div>
          </form>
          <div className="w-full h-96 mx-auto md:mb-12 mb-24 mt-8">
            <p className="dark-text font-bold lg:text-5xl text-4xl mb-4">
              Where are we located?
            </p>
            <p className="mb-4">
              Use the map below to find our pins! We are located in Canada, UK
              and New Zealand
            </p>
            <Map />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full mt-16 bg-slate-200 p-16 w">
        <p className="dark-text lg:text-5xl text-4xl font-bold">
          Alternative ways to contact us
        </p>
        <div className="flex flex-col md:flex-row mt-8 text-dark justify-center w-full">
          <div className="flex flex-col text-left bg-white p-4 rounded shadow my-2 md:mr-8 ">
            <FontAwesomeIcon
              className="text-orange"
              icon={faBuilding}
              size="3x"
            />
            <p className="text-dark text-3xl font-bold mb-3 text-center">
              Our Head Office
            </p>
            <p>BuzzHubs</p>
            <p>272 Bath Street</p>
            <p>Glasgow</p>
            <p>Scotland</p>
            <p>G2 4JR</p>
          </div>
          <div className="flex flex-col text-left bg-white p-4 rounded shadow md:mx-8 my-2 text-center">
            <FontAwesomeIcon
              className="text-orange"
              icon={faPhoneAlt}
              size="3x"
            />
            <p className="text-dark text-3xl font-bold mb-3">Contact Number</p>
            <p>0117 205 1115</p>
            <a
              className="py-2 mt-auto px-4 bg-orange text-white rounded hover:bg-orange-700 mt-4"
              href="tel:+447907129412"
            >
              Call Us
            </a>
          </div>
          <div className="flex flex-col text-left bg-white p-4 rounded shadow md:ml-8 my-2 text-center">
            <FontAwesomeIcon
              className="text-orange"
              icon={faEnvelope}
              size="3x"
            />
            <p className="text-dark text-3xl font-bold mb-3">Email Address</p>
            <p>info@buzzhubs.com</p>
            <a
              className="py-2 px-4 mt-auto bg-orange text-white rounded hover:bg-orange-700 mt-4"
              href="mailto: info@buzzhubs.com"
            >
              Email Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
