import React from "react";

export default function TermsPolicy() {
  return (
    <div className="xl:w-3/4 mx-auto">
      <div
        name="termly-embed"
        data-id="979a1a9f-2466-4a4d-b2ea-aa7eb4254fbc"
        data-type="iframe"
      ></div>
    </div>
  );
}
