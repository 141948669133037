import React from "react";
import {
  faCircleCheck,
  faCircleExclamation,
  faCommentAlt,
  faDownload,
  faMobileAlt,
  faSpinner,
  faThumbsUp,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hero from "../../assets/images/homepage-hero.jpg";
import SOSImg from "../../assets/images/SOS.png";
import aiAssistance from "../../assets/images/ai-assistance.jpg";
import trophy from "../../assets/images/trophy.png";
import b12 from "./../../assets/images/user-badge/b12.png";
import A1 from "./../../assets/images/user-badge/a1.png";
import congratsGif from "../../assets/images/congrats-congratulations.gif";
import lunarCrm from "../../assets/images/integrations/lunar.png";
import bullhorn from "../../assets/images/integrations/bullhorn.png";
import cloudcall from "../../assets/images/integrations/cloudcall.png";
import dialpad from "../../assets/images/integrations/dialpad.png";
import teams from "../../assets/images/integrations/teams.png";

export default function Home() {
  
  return (
    <div className="flex flex-col">
      <div className="flex flex-col xl:w-3/4 w-5/6 mx-auto">
        <div className="order-2 w-full text-center mb-6 mx-auto ">
          
            <p className="xl:text-6xl lg:text-6xl md:text-5xl text-3xl my-6 text-dark font-bold">
              Make more sales using employee recognition, motivation and engagement
            </p>
            <p className="xl:text-2xl lg:text-2xl md:text-xl text-lg mb-6 text-dark">
              Use data from your existing systems in one easy-to-use place so managers can focus on increasing sales and employees get the recognition they deserve
            </p>

            <button
              onClick={() => (window.location.href = "/contact-us")}
              className="lg:my-1 my-6 rounded-md bg-orange px-6 py-2.5 text-white text-md lg:text-lg w-60 xl:mx-0 mx-auto"
            >
              Start a conversation
            </button>
          
            <p className="text-md text-dark mt-3">
              (Find out more, schedule a demo or arrange a no-obligation free trial)
            </p>

        </div>

        <div className="order-1 pt-16 w-full">
          <div className="h-full flex flex-col justify-center">
            <img src={hero} alt="BuzzHubs" />
          </div>
        </div>
      </div>

      <div className="bg-slate-100">
        <div
          id="recognition"
          className="bg-slate-100 flex xl:flex-row flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center"
        >
          <div className="w-full md:w-4/5 lg:w-3/4 xl:w-1/2 lg:pr-16 py-16 xl:text-left text-center">
            <div className="h-full flex flex-col justify-center  xl:items-start">
              <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
                <span className="text-orange font-bold">Recognise</span> your
                heroes
              </p>
              <p className="text-lg mb-6 text-dark text-justify">
                Peer-to-peer recognition is 35.7% more likely to have a positive impact on financial results than manager-only recognition.
              </p>
              <ul className="list-disc list-inside text-dark">
                <li>Achievements automatically picked up from other systems such as your CRM</li>
                <li>Social media style Feed allows peers to like and comment on achievements</li>
                <li>Big wins highlighted in the Feed and on your Breaking News</li>
                <li>Automation to ensure consistency so no one gets missed</li>
              </ul>
            </div>
          </div>

          <div className="w-full md:w-4/5 lg:w-3/4 xl:w-1/2 my-10">
            <div className="bg-white mx-auto flex gap-3 flex-col rounded py-2 px-4 shadow-xl w-fit max-w-md">
              <div className="flex gap-3 items-center">
                <img className="h-14" src={A1} alt="user" />
                <div className="text-left text-slate-600">
                  <p className="font-bold">Aaron</p>
                  <p>13:50</p>
                </div>
              </div>
              <div className="text-left text-lg">
                <p>
                  We have just hit our first customer service milestone. We now
                  have 300 reviews!
                </p>
              </div>
              <div>
                <img className="w-2/3 mx-auto" src={congratsGif} alt="post" />
              </div>
              <div className="flex text-xl text-slate-500 gap-7">
                <FontAwesomeIcon icon={faThumbsUp} />
                <FontAwesomeIcon icon={faCommentAlt} />
              </div>
              <div className="flex gap-4 items-center text-md">
                <div className="w-8">
                  <img src={b12} alt="user" />
                </div>
                <p>
                  Liked by <span className="font-bold">James</span> and{" "}
                  <span className="font-bold">8 others</span>
                </p>
              </div>
              <div className="text-md text-left">
                <p>
                  <span className="font-bold">Alex Downs:</span> Thats superb!!
                  Well done everyone!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          id="aiassistance"
          className="flex xl:flex-row flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center py-8"
        >
          <div className="order-2 xl:order-1 w-full md:w-4/5 lg:w-3/4 xl:w-1/2 pb-16">
            <img className="mx-auto" src={aiAssistance} alt="BuzzHubs" />
          </div>
          <div className="order-1 xl:order-2 w-full md:w-4/5 lg:w-3/4 xl:w-1/2 xl:pl-16 py-16 xl:text-left text-center">
            <div className="h-full flex flex-col justify-center  xl:items-start">
              <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
                <span className="text-orange font-bold">AI assistance</span> for your managers
              </p>
              <p className="text-lg mb-6 text-dark text-justify">
                Give your managers the tools they need to drive sales.  Our AI engine running in the background identifies in real-time when employees are approaching milestones or may need assistance.
              </p>
              <ul className="list-disc list-inside text-dark">
                <li>Motivate employees as they approach milestones such as personal bests or targets</li>
                <li>Recognise trends from historic data to identify outperforming employees or those who may need some help</li>
                <li>Increased efficiency by automatic gathering and analysing of information from multiple systems</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-100">
        <div
          id="motivation"
          className="flex xl:flex-row flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center py-8"
        >
          <div className="w-full md:w-4/5 lg:w-3/4 xl:w-1/2 lg:pr-16 py-16 xl:text-left text-center">
            <div className="h-full flex flex-col justify-center  xl:items-start">
              <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
                <span className="text-orange font-bold">Motivate and engage</span> your employees
              </p>
              <p className="text-lg mb-6 text-dark text-justify">
                Create a high performance culture through healthy competition and engaging your workforce with:
              </p>
              <ul className="list-disc list-inside text-dark">
                  <li>Real-time leaderboards</li>
                  <li>Personal, team and company targets</li>
                  <li>Share wins and milestones reached</li>
                </ul>
            </div>
          </div>

          <div className="w-full md:w-4/5 lg:w-3/4 xl:w-1/2 pb-16">
            <img className="mx-auto" src={trophy} alt="BuzzHubs" />
          </div>
        </div>
      </div>

      <div>
        <div
          id="remoteready"
          className="flex xl:flex-row flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center py-8"
        >
          <div className="order-2 xl:order-1 w-full md:w-4/5 lg:w-3/4 xl:w-1/2 pb-16">
            <img className="mx-auto" src={SOSImg} alt="BuzzHubs" />
          </div>
          <div className="order-1 xl:order-2 w-full md:w-4/5 lg:w-3/4 xl:w-1/2 xl:pl-16 py-16 xl:text-left text-center">
            <div className="h-full flex flex-col justify-center  xl:items-start">
              <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
                <span className="text-orange font-bold">Remote</span> Ready
              </p>
              <p className="text-lg mb-6 text-dark text-justify">
                Create a common culture and provide a set of tools so employees can thrive whether working remotely, in the office, or a combination of both.
              </p>
              <ul className="list-disc list-inside text-dark">
                <li>Remote SOS to connect employees with managers when immediate assistance is required</li>
                <li>Real-time availability information to ease communication issues e.g. integrations with VoiP systems show who is on a call; calendar information (such as meetings) and other status updates show when someone is available</li>
                <li>Integrated Feed and Messaging Centre to keep in touch with peers and keep up with company news and achievements</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-100">
        <div className="flex flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center py-16">
          <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
            <span className="text-orange">Testimonials</span>
          </p>

          <div className="flex flex-col text-dark gap-3">
            <div className="md:text-left flex flex-col md:flex-row gap-3 items-center">
              <div className="w-20 h-20 md:mx-0">
                <img src={b12} alt="Testimonial" />
              </div>
              <div>
                <p className="text-lg">Blair  Mitchell</p>
                <p className="italic text-sm">
                  Head of Compliance, Bon Accord Life
                </p>
                <p>*****</p>
              </div>
            </div>

            <p className="text-justify">
              “I worked from home before covid and to be honest I didn’t think I
              needed any more tools than the video calling and messaging I was
              already using. I have to say though, since I started using BuzzHubs
              I’ve never felt such a big part of the business. I like the way I
              can post announcements and then see people like and comment on it. I
              can also now see everyone in the company and see what they are
              achieving whereas before I guess I was pretty isolated from
              everyone”
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center py-16">
          <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
            Integrations
          </p>

          <div className="flex flex-col text-dark gap-3 mb-6">
            <p className="text-lg">Seamless integration with CRMs, VoiP, engagement systems and others</p>
          </div>

          <div className="grid grid-cols-5 gap-8 items-center">
            <img src={lunarCrm} alt="Lunar CRM" />                        
            <img src={cloudcall} alt="Cloudcall" /> 
            <img src={bullhorn} alt="Bullhorn" />                        
            <img src={dialpad} alt="Dialpad" /> 
            <img src={teams} alt="Teams" /> 
          </div>

        </div>
      </div>

      <div className="bg-slate-100">
        <div
          id="contact us"
          className="flex flex-col w-screen xl:w-3/4 w-4/5 mx-auto justify-center items-center py-8"
        >
          <p className="xl:text-7xl lg:text-6xl md:text-6xl text-5xl mb-6 text-dark">
          <span className="text-orange">Find Out More</span>
          </p>
          <p className="xl:text-2xl lg:text-2xl md:text-xl text-lg mb-6 text-dark">
              Click below to schedule a call, arrange a demo or organise a free trial and find out how BuzzHubs can help your business increase sales.
          </p>
          <button
              onClick={() => (window.location.href = "/contact-us")}
              className="lg:my-1 my-6 rounded-md bg-orange px-6 py-2.5 text-white text-md lg:text-lg w-60 xl:mx-0 mx-auto"
            >
              Contact Us
          </button>
        </div>
      </div>

    </div>
  );
}
