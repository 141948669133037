import React from "react";

export default function Footer() {
  return (
    <div className="px-4 py-14 w-full bg-slate-700 mb-32">
      <div className="xl:w-3/4 mx-auto ">
        <div className="flex justify-start h-full text-white">
          <div className="flex flex-col text-left w-1/2 md:w-1/4">
            <p className="text-lg mb-4">Features</p>
            <p className="text-md mb-3">
              <a href="/#recognition">Employee Recognition</a>
            </p>
            <p className="text-md mb-3">
              <a href="/#aiassistance">AI Assistance for sales managers</a>
            </p>
            <p className="text-md mb-3">
              <a href="/#motivation">
                Motivate and engage your employees
              </a>
            </p>
            <p className="text-md mb-3">
              <a href="/#remoteready">Remote Ready</a>
            </p>
            <p className="text-md mb-3">
              <a href="/#remotesos">Remote SOS</a>
            </p>
          </div>
          <div className="flex flex-col text-left w-1/2 md:w-1/4">
            <p className="text-lg mb-4">About</p>
            <p className="text-md mb-3">
              <a href="/pricing">Pricing</a>
            </p>
            <p className="text-md mb-3">
              <a href="/about-us">Our Company</a>
            </p>
            <p className="text-md mb-3">
              <a href="https://blog.buzzhubs.com/">Blog</a>
            </p>
            <p className="text-md mb-3">
              <a href="/contact-us">Contact</a>
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row text-slate-500 mt-8 justify-between items-center">
          <p className="mb-4 md:mb-0">
            Copyright {new Date().getFullYear()} @ BuzzHubs
          </p>
          <div className="flex items-center">
            <a href="/terms-policy" className="md:mr-8 mr-4">
              Terms of Use
            </a>
            <a href="/privacy-policy" className="md:mr-8 mr-4">
              Privacy Policy
            </a>
            <a href="/cookie-policy" className="md:mr-8 mr-4">
              Cookie Policy
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.linkedin.com/company/buzzhubs/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="50px"
                width="50px"
                viewBox="0 0 448 512"
                style={{
                  filter:
                    "invert(77%) sepia(59%) saturate(6340%) hue-rotate(197deg) brightness(99%) contrast(108%)",
                }}
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex text-slate-500 mt-2">
          <p>
            Registered No: SC659829. BuzzHubs is a registered trademark of
            BuzzHubs Ltd. 272 Bath Street, Glasgow, Scotland, G2 4JR.
          </p>
        </div>
      </div>
    </div>
  );
}
