import React from "react";

export default function CookiePolicy() {
  return (
    <div className="xl:w-3/4 mx-auto">
      <div
        name="termly-embed"
        data-id="5ca53441-f84f-4b3b-b9cc-5f265ba19513"
        data-type="iframe"
      ></div>
    </div>
  );
}
