import React from "react";
import Flag from "react-world-flags";

export default function TeamMemberCard({ member }) {
  const [readMore, setReadMore] = React.useState(false);

  return (
    <div
      className="flex-initial p-4 mb-4 w-full sm:w-72 lg:w-80 w-full flex flex-col gap-3"
      key={`memberCard-${member.FirstName}`}
    >
      <img
        src={member.ImageUrl}
        alt={member.FirstName}
        className="rounded-full mx-auto mb-4 w-64 h-64"
      />
      <div className="flex flex-row justify-center items-center">
        <p className="text-xl">
          {member.FirstName} {member.LastName}
        </p>
        <div className="w-8 ml-3">
          <Flag code={member.Flag} />
        </div>
      </div>

      <p>
        <i>{member.Title}</i>
      </p>
      <div className={`${readMore ? "h-fit" : "h-0"} overflow-hidden`}>
        <p className="text-justify">{member.Description}</p>
      </div>
      <span
        onClick={() => setReadMore(!readMore)}
        className="cursor-pointer bg-orange text-orange-900 p-2 w-fit rounded mx-auto"
      >
        Read {readMore ? "less" : null} {"about " + member.FirstName}
      </span>
    </div>
  );
}
