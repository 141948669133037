import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./components/about/About";
import BookDemo from "./components/contact/BookDemo";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Introducers from "./components/introducers/Introducers";
import CookiePolicy from "./components/policies/CookiePolicy";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import TermsPolicy from "./components/policies/TermsPolicy";
import Pricing from "./components/pricing/Pricing";

function App() {
  return (
    <Router>
      <div className="App relative">
        <Header />
        <Routes>
          <Route path="/introducers" element={<Introducers />} />
          <Route path="/terms-policy" element={<TermsPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/book-a-demo" element={<BookDemo />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
